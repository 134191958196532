<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="900"
    scrollable
    persistent
    @keydown.esc="goToClientsPage"
  >
    <ClientForm
      :client="editedClient"
      :errors="clientValidationErrors"
      :disabled="$store.getters.loading[`put:api/clients/${editedClient.id}`]"
      @clear:errors="clearClientValidationErrors"
      @cancel="goToClientsPage"
      @save="onSave"
    />
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import store from '@/store';
import ClientForm from '@/components/ClientForm';

export default {
  name: 'EditClient',

  components: { ClientForm },

  computed: mapState('clients', ['editedClient', 'clientValidationErrors', 'clientFilterParams']),

  beforeRouteEnter(to, from, next) {
    store
      .dispatch('clients/editClient', +to.params.id)
      .then(() => {
        next();
      })
      .catch(() => {
        next({ name: 'clients', query: store.state.clients.clientFilterParams });
      });
  },

  methods: {
    ...mapActions('clients', ['updateClient', 'clearClientValidationErrors']),

    onSave(client) {
      this.updateClient(client).then(() => {
        this.goToClientsPage();
      });
    },

    goToClientsPage() {
      this.$router.push({ name: 'clients', query: this.clientFilterParams });
    },
  },
};
</script>

<style scoped></style>
